<template>
  <a-modal
    :visible="visible"
    title="Src处理"
    :width="700"
    @cancel="handleCancel"
    @ok="submit"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      layout="horizontal"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="名称" ref="name" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="地址" ref="src_address" prop="src_address">
        <a-input v-model="form.src_address"></a-input>
      </a-form-model-item>
      <a-form-model-item label="介绍" ref="introduction">
        <a-input v-model="form.introduction"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { changeSrc, addSrc } from "@/api/information";
export default {
  data() {
    return {
      id: 0,
      visible: false,
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: "",
        src_address: "",
        introduction: "",
      },
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        src_address: [
          { required: true, message: "地址不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.id == 0) {
            addSrc(this.form)
              .then((res) => {
                if (res.status === 201) {
                  this.visible = false;
                  this.$refs["ruleForm"].resetFields();
                  this.$parent.$parent.fetch();
                } else {
                  this.$message.error("添加失败!");
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          } else {
            changeSrc(this.id, this.form)
              .then((res) => {
                if (res.status === 200) {
                  this.visible = false;
                  this.$refs["ruleForm"].resetFields();
                  this.$parent.$parent.fetch();
                } else {
                  this.$message.error("更改失败!");
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          }
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.$refs["ruleForm"].resetFields();
      this.id = 0;
      this.visible = false;
    },
    init(id = 0, name = "", src_address = "", introduction = "") {
      this.id = id;
      this.form.name = name;
      this.form.src_address = src_address;
      this.form.introduction = introduction;
      this.visible = true;
    },
  },
};
</script>
