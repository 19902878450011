<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <a-button type="primary" icon="plus" @click="add">添加SRC</a-button>
    <a-input-search
      placeholder="全局搜索"
      style="width: 300px; float: right"
      v-model="globalSearch"
      @search="onSearch"
    />
    <a-table
      ref="table"
      style="width: 100%; margin-top: 10px"
      size="small"
      :loading="loading"
      :rowKey="(row) => row.id"
      :columns="columns"
      :data-source="data"
      showPagination="auto"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <span slot="address" slot-scope="text">
        <a-button target="_blank" :href="text" type="link">
          {{ text }}
        </a-button>
      </span>
      <span slot="action" slot-scope="src">
        <a-button
          type="primary"
          size="small"
          style="margin-right: 8px"
          @click="editSrc(src)"
          >编辑
        </a-button>
        <a-button type="danger" size="small" @click="delSrc(src)">
          删除
        </a-button>
      </span>
    </a-table>
    <src-add ref="srcAdd" />
  </a-card>
</template>

<script>
import { getSrc, deleteSrc } from "@/api/information";
import SrcAdd from "./modules/SrcAdd";
import { Modal } from "ant-design-vue";
export default {
  components: {
    SrcAdd,
  },
  data() {
    return {
      globalSearch: "",
      loading: false,
      data: [],
      columns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          scopedSlots: { customRender: "id" },
          width: 80,
        },
        {
          title: "名称",
          dataIndex: "name",
          ellipsis: true,
          scopedSlots: { customRender: "name" },
          width: 80,
        },
        {
          title: "地址",
          dataIndex: "src_address",
          ellipsis: true,
          scopedSlots: { customRender: "address" },
          width: 80,
        },
        {
          title: "介绍",
          dataIndex: "introduction",
          ellipsis: true,
          scopedSlots: { customRender: "introduction" },
          width: 80,
        },
        {
          title: "操作",
          ellipsis: true,
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        current: 1,
        pageSize: 10,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        // page_size: pagination.pageSize,
        // page: pagination.current,
        // ...filterDic,
        // ...orderingDic,
      });
    },
    onSearch(value) {
      this.globalSearch = value;
      this.pagination.current = 1;
      this.fetch({ page: 1 });
    },
    fetch(query = {}) {
      this.loading = true;
      if (this.globalSearch) {
        query["search"] = this.globalSearch;
      }
      query["page_size"] = this.pagination.pageSize;
      if (query["page"] === undefined) {
        query["page"] = this.pagination.current;
      }
      getSrc(query)
        .then((res) => {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.loading = false;
          this.data = res.data.results;
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },
    add() {
      this.$refs["srcAdd"].init();
    },
    editSrc(src) {
      this.$refs["srcAdd"].init(
        src.id,
        src.name,
        src.src_address,
        src.introduction
      );
    },
    delSrc(src) {
      Modal.confirm({
        title: "警告",
        content: "您确定要删除此Src吗?",
        onOk: () => {
          deleteSrc(src.id)
            .then((res) => {
              if (res.status === 204) {
                this.$message.success("删除成功!");
                this.fetch();
              } else {
                this.$message.error("删除失败!");
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        },
        onCancel() {},
      });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
